.instit-img {
    background: url('../../assets/images/hr-solutions.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 20rem;
    margin-bottom: 1.5rem;
}

.instits-img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 20rem;
    margin-bottom: 1.5rem;
}

.content-img-wrapper {
    .excelence-img {
        width: 100%;
        height: 20rem;
        margin-top: 20px;
    }

    .hr-soultions-content {
        text-align: left;
        width: 100%;

        h3 {
            font-weight: 500;

            // &:before {
            //     position: absolute;
            //     content: "";
            //     // left: 0;
            //     bottom: 0;
            //     height: 2px;
            //     width: 50px;
            //     background: #f3acac;
            // }
        }

        .hr-solution-quote {
            font-size: 2rem;
            font-weight: 600;
            margin: 1rem 0 3rem;
            font-style: italic;
        }

        .hr-solutions-description {
            line-height: 1.5rem;
            text-align: justify;
            text-indent: 5em;
        }
    }
}

@media only screen and (max-width: 999px) {
    .content-img-wrapper {
        box-shadow: 0px 10px 10px #00000024;
    }

    .cln-r {
        flex-direction: column;
    }
}