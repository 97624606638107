.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

* {
  box-sizing: border-box;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slideInFromLeft-img {
  opacity: 0;
  animation: slideInFromLeft 1s ease-in-out forwards;

  &.active {
    animation: slideInFromLeft 1s ease-in-out forwards;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slideInFromRight-img {
  opacity: 0;
  animation: slideInFromRight 1s ease-in-out forwards;

  &.active {
    animation: slideInFromRight 1s ease-in-out forwards;
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

/* Apply the animation to the image */
.slideInFromBottom-img {
  opacity: 0;
  animation: slideInFromBottom 1s ease-in-out forwards;

  &.active {
    animation: slideInFromBottom 1s ease-in-out forwards;
  }
}