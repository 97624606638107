.social-media-icons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    a {
        text-decoration: none;
        color: #333;

        &:hover {
            color: #CF1A01;
        }
    }
}