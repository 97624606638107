.contact-warp {
    width: 100%;
    padding: 1rem 5rem;

    .containers {
        width: 100%;
        background: none;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;

        .inner {
            box-shadow: 0px 0px 10px #00000024;
            border-radius: 5px;
            overflow: hidden;

            .row {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                margin-right: -15px;
                margin-left: -15px;

                .col-lg-6 {
                    -ms-flex: 0 0 50%;
                    flex: 0 0 50%;
                    max-width: 50%;

                    .contact-us-form {
                        padding: 20px 40px;

                        h2 {
                            position: relative;
                            font-size: 32px;
                            color: #333;
                            font-weight: 600;
                            line-height: 27px;
                            text-transform: capitalize;
                            //margin-bottom: 12px;
                            padding-bottom: 20px;
                            text-align: left;

                            &:before {
                                position: absolute;
                                content: "";
                                left: 0;
                                bottom: 0;
                                height: 2px;
                                width: 50px;
                                background: #1A76D1;
                            }
                        }

                        p {
                            font-size: 14px;
                            color: #CF1A01;
                            font-weight: 500;
                            text-align: left;
                            margin-bottom: 50px;
                            text-align: justify;
                            line-height: 1.5rem;
                            margin: 0px;
                            padding: 0px;
                        }

                        .form {
                            margin-top: 10px;

                            .row {
                                display: -ms-flexbox;
                                display: flex;
                                -ms-flex-wrap: wrap;
                                flex-wrap: wrap;
                                margin-right: -15px;
                                margin-left: -15px;

                                .user-name {
                                    width: calc(100% - 20px);
                                    display: flex;
                                    align-items: center;
                                    gap: 20px;

                                    .col-lg-6 {
                                        -ms-flex: 0 0 50%;
                                        flex: 0 0 50%;
                                        max-width: 50%;


                                        .form-group {
                                            //margin-bottom: 22px;
                                            .contact-text-feild{
                                                margin: 0px;
                                            }

                                            input,
                                            textarea {
                                                width: 100%;
                                                border: 1px solid #eee;
                                                text-transform: capitalize;
                                                padding: 0px 18px;
                                                color: #555;
                                                font-size: 14px;
                                                font-weight: 400;
                                                border-radius: 4px;
                                            }

                                            input {
                                                height: 50px;
                                            }

                                            textarea {
                                                height: 100px;
                                            }
                                        }
                                    }
                                }

                                .col-lg-12 {
                                    -ms-flex: 0 0 100%;
                                    flex: 0 0 100%;
                                    max-width: 100%;

                                    .form-group {
                                        //margin-bottom: 22px;
                                        .contact-text-feild{
                                            margin-bottom: 5px;
                                        }

                                        input,
                                        textarea {
                                            width: 100%;
                                            // border: 1px solid #eee;
                                            text-transform: capitalize;
                                            padding: 0px 18px;
                                            color: #555;
                                            font-size: 14px;
                                            font-weight: 400;
                                            border-radius: 4px;
                                        }

                                        input {
                                            height: 50px;
                                        }

                                        textarea {
                                            height: 100px;
                                        }
                                    }
                                }

                                .col-12 {
                                    -ms-flex: 0 0 100%;
                                    flex: 0 0 100%;
                                    max-width: 100%;

                                    .login-btn {
                                        margin-bottom: 22px;
                                        margin: 0;

                                        .btn {
                                            display: inline-block;
                                            //margin-right: 10px;
                                            color: #fff;
                                            line-height: 35px;
                                            width: 50%;

                                            &:hover {
                                                background: #1A76D1;
                                                color: #fff;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .contact-info {
            margin-top: 20px;
            margin-bottom: 30px;
            display: flex;
            justify-content: space-between;


            // .row {
            //     display: -ms-flexbox;
            //     display: flex;
            //     -ms-flex-wrap: wrap;
            //     flex-wrap: wrap;
            //     // margin-right: -15px;
            //     // margin-left: -15px;
            //     justify-content: space-between;

            .col-lg-4 {
                -ms-flex: 0 0 30%;
                flex: 0 0 30%;
                max-width: 30%;
                // -ms-flex: 0 0 100%;
                // flex: 0 0 100%;
                // max-width: 100%

                .single-info {
                    background: #eee;
                    height: 150px;
                    border-radius: 10px;
                    position: relative;
                    transition: all 0.3s ease-out 0s;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:before {
                        position: absolute;
                        z-index: -1;
                        content: '';
                        bottom: -10px;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        width: 80%;
                        height: 90%;
                        opacity: 0;
                        filter: blur(10px);
                        transition: all 0.3s ease-out 0s;
                    }

                    &:hover:before {
                        opacity: 0.8;
                    }

                    &:hover {
                        transform: translateY(-5px);
                    }

                    .content {
                        padding: 10px;

                        h3 {
                            margin-top: 0;
                            font-size: 18px;
                            font-weight: 500;
                        }

                        p {
                            font-size: 16px;
                            font-style: italic;
                            font-weight: 500;
                            color: #CF1A01;
                            overflow: hidden;
                            word-break: break-word;
                        }

                    }
                }
            }

            // }    


        }

    }

    .lft-cont {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;

        .tit{
            color: red;
            text-align: start;
            margin: 0px;
            width: 60%;
            font-size: 24px;
        }

        .pt{
            color: black;
            font-size: 80px;
            font-weight: 600;
            font-style: italic;
            margin: 0px;
        }
    }

}

@media only screen and (max-width: 670px) {
    .contact-warp {
        width: 100%;
        padding: 0;

        .containers {
            width: 100%;
            display: flex;
            flex-direction: column;
            // padding-right: 15px;
            // padding-left: 15px;
            margin-right: auto;

            .inner {
                box-shadow: 0px 0px 10px #00000024;
                border-radius: 5px;
                overflow: hidden;

                .md-row {
                    flex-direction: column;
                }

                .row {
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    // margin-right: -15px;
                    // margin-left: -15px;

                    .col-lg-6 {
                        -ms-flex: 0 0 100%;
                        flex: 0 0 100%;
                        max-width: 100%;


                    }
                }
            }

            .contact-info {
                margin-top: 50px;
                flex-direction: column;

                .col-lg-4 {
                    max-width: 100%;
                    margin-bottom: 10px;
                }


            }

        }

    }
}