.head-hr-solution {
    position: relative;
    font-size: 22px;
    color: #333;
    font-weight: 600;
    line-height: 27px;
    text-transform: capitalize;
    margin-bottom: 5px;
    padding-bottom: 5px;

    &:before {
        position: absolute;
        content: "";
        // left: 0;
        bottom: 0;
        height: 2px;
        width: 50px;
        background: #f3acac;
    }
}

.hr-solution h3 {
    position: relative;
    padding-bottom: 5px;

    &:before {
        position: absolute;
        content: "";
        // left: 0;
        bottom: 0;
        height: 4px;
        width: 50px;
        background: #f3acac;
    }
}

.hr-solution-img {
    background: url('../../assets/images/hr-solutions.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 20rem;
    margin-bottom: 1.5rem;
}

.content-img-wrapper {
    .hr-solution-img {
        //width: 100%;
        height: 30rem;
    }

    .col-12 {
        -ms-flex: 0 0 60%;
        flex: 0 0 60%;
        max-width: 60%;

        .blog-comments {

            margin-top: 30px;
            background: transparent;
            -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
            -moz-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
            border-radius: 8px;
            padding: 30px;

            .comments-body {
                .single-comments {
                    margin-bottom: 30px;
                    background: #fff;
                    border-bottom: 1.5px solid #CF1A01;
                    // padding-bottom: 30px;

                    .main {

                        .body {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 14px;
                            color: #888;

                            h2 {
                                position: relative;
                                font-size: 22px;
                                color: #333;
                                font-weight: 600;
                                line-height: 27px;
                                text-transform: capitalize;
                                // margin-bottom: 12px;
                                padding-bottom: 5px;
                                text-align: left;
                            }

                            p {
                                font-weight: 400;
                                text-transform: capitalize;
                                font-size: 14px;
                                letter-spacing: 0px;
                                line-height: 23px;
                                font-style: italic;
                                text-align: justify;
                            }
                        }
                    }
                }

                .left {
                    border-bottom: none;
                }
            }
        }
    }
}

.out-content {
    display: flex;
    background: aliceblue;
    margin-bottom: 20px;

    h3 {
        text-align: center;
    }

    .our-vision {
        width: 50%;
        text-align: justify;
        margin: 0% 5%;
    }

    .our-mission {
        width: 50%;
        text-align: justify;
        margin: 0% 5%;
    }
}

.cnt-who {
    display: flex;
    width: 100%;

}

@media only screen and (max-width: 999px) {
    .hr-solution {
        .out-content{
            flex-direction: column;
            .our-vision{
                width: calc(100% - 10%);
            }
            .our-mission{
                width: calc(100% - 10%);
            }
        }


        .content-img-wrapper {
            .hr-solution-img {
                height: 25rem;
            }
             .col-12 {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

    }

    

}