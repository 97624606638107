/* BlinkingIcon.css */
.blinking-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
  }
  
  .blinking-icon img {
    width: 60%;
    height: 30%;
    animation: blink 1s infinite;
  }
  
  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  /* Media query for mobile devices */
  @media (max-width: 500px) {
    .blinking-icon img {
      width: 200px;
      height: 80px;
    }
  }
  