.header {
    box-sizing: border-box;
    left: 0;
    position: sticky;
    top: 0;
    transition: padding .14s ease-in-out;
    transition: all .25s ease;
    width: 100%;
    z-index: 2;
    // height: 80px;
    background-color: #fff6f6;

    .header-wrap {
        display: flex;
        padding: 0.5rem 5rem;
        justify-content: space-between;
        align-items: center;

        .logo-wrap {
            height: 3rem;
        }

        .menu-wrapper {
            display: flex;
            align-items: center;
            gap: 1rem;

            .activeMenu {
                color: #CF1A01;
            }

            a {
                cursor: pointer;
                font-weight: 500;
                text-decoration: none;
                color: #333;

                &:hover {
                    color: #CF1A01;
                }
            }
        }

        .mobile-menu {
            display: none;

        }
    }
}

@media only screen and (max-width: 999px) {
    .header {
        .header-wrap {
            width: 100%;
            padding: 0.5rem 1.5rem;

            .menu-wrapper {
                display: none;
            }

            .mobile-menu {
                display: block;
            }
        }

        .mobile-menu {
            transition: all .25s ease;

            a {
                cursor: pointer;
                font-weight: 500;
                // float: left;
                display: flex;
                width: 100%;
                padding: 6px;
                text-decoration: none;


                &:hover {
                    color: #CF1A01;
                }
            }
        }
    }


}