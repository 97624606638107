.footer {
    display: flex;
    padding: 1rem 5rem;
    justify-content: space-between;
    background-color: rgb(175, 179, 180);

    .about-us-wrapper {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        justify-content: center;
    }

    .about-us-content {
        margin: 0;
        font-style: italic;
        line-height: 1.5rem;
        text-align: justify;
        text-indent: 5em;
    }

    .logo-wrap {
        height: 1.5rem;
    }

    div {
        flex: 1
    }

    .quick-links {
        a {
            cursor: pointer;
            font-weight: 400;
            font-style: italic;
            color: #333;
            text-decoration: none;
            display: block;
            margin: 8px 0;

            &:hover {
                color: #CF1A01;
            }
        }
    }

    .footer-address {
        margin: 8px;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        justify-content: center;
        font-style: italic;

    }

    .footer-mobile,
    .footer-mail {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        justify-content: center;
        margin: 8px 0;
        font-style: italic;

        a {
            color: #333;
            text-decoration: none;

            &:hover {
                color: #CF1A01;
            }
        }
    }
}

@media only screen and (max-width: 999px) {
    .footer {
        flex-direction: column;
        padding: 1rem 1.5rem;
    }

}