.hr-banner-img {
    background: url('../../assets/images/slide-career.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 20rem;
    margin-bottom: 1.5rem;
}

.content-img-wrapper {
    display: flex;
    align-items: center;
    padding: 0 5rem;
    gap: 1rem;
    margin-bottom: 1.5rem;

    .welcome-content {
        text-align: left;

        h3 {
            position: relative;
            padding-bottom: 5px;

            &:before {
                position: absolute;
                content: "";
                // left: 0;
                bottom: 0;
                height: 4px;
                width: 150px;
                background: #f3acac;
            }
        }

        p {
            line-height: 1.5rem;
            text-align: justify;
            text-indent: 5em;
        }

        .subtitle-header {
            margin: 8px 0;
        }

        .subtitle-content {
            margin: 8px 0;

        }

    }

    .contant-us-card {
        padding: 1.5rem;
        background: #eee;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
        margin: 16px 0 0;
        border-radius: 16px;

        .ques-content {
            font-size: 14px;
            font-style: italic;
            font-weight: 500;
        }

        .thanks-content {
            font-size: 14px;
            font-style: italic;
            color: #CF1A01;
            font-weight: 500;
        }

        .mail-phone-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 8px 0;
            font-size: 12px;

            .mail-wrap {
                display: flex;
                align-items: center;
                gap: 0.25rem;

                a {
                    color: #333;
                    font-size: 16px;
                    text-decoration: none;
                    display: flex;
                    align-items: center;

                    &:hover {
                        color: #CF1A01;
                    }

                    svg {
                        width: 18px;
                        height: 18px;
                        margin: 5px;
                        display: flex;
                        align-items: center;
                    }
                }
            }

            .contact-icon-wrapper {
                padding: 0.5rem;
                background-color: #CF1A01;
                border-radius: 50px;
                // transition:;

                a {
                    text-decoration: none;
                    color: #eee;

                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }

                .icon {
                    animation: pulse-animation 2s infinite;
                    background-color: #CF1A01 !important;
                    color: #f9f9f9 !important;
                    width: auto !important;
                }
            }

        }
    }
}

.container {
    width: 100%;
    padding: 2.5rem 5rem;
    background: #fff6f6;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2rem;

    .schedule-inner {

        .row {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            justify-content: space-between;

            .col-lg-4 {
                -ms-flex: 0 0 22%;
                flex: 0 0 22%;
                max-width: 22%;

                .single-schedule {
                    // position: relative;
                    height: 100%;
                    text-align: left;
                    // z-index: 3;
                    border-radius: 16px;
                    background: #eee;
                    -webkit-transition: all .3s ease-out 0s;
                    -moz-transition: all .3s ease-out 0s;
                    -ms-transition: all .3s ease-out 0s;
                    -o-transition: all .3s ease-out 0s;
                    transition: all .3s ease-out 0s;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
                    // bottom: 0;

                    .inner {
                        overflow: hidden;
                        position: relative;
                        padding: 30px;
                        // z-index: 2;

                        .single-content {
                            h4 {
                                margin-top: 0;
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 1.5rem;
                            }

                            p {
                                font-size: 14px;
                                font-style: italic;
                                font-weight: 500;
                                color: #CF1A01;
                                text-align: justify;
                                line-height: 1.5rem;
                            }
                        }
                    }
                }

                &:before {
                    position: absolute;
                    z-index: -1;
                    content: '';
                    bottom: -10px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    width: 80%;
                    height: 90%;
                    // background: #1A76D1;
                    opacity: 0;
                    filter: blur(10px);
                    -webkit-transition: all .3s ease-out 0s;
                    -moz-transition: all .3s ease-out 0s;
                    -ms-transition: all .3s ease-out 0s;
                    -o-transition: all .3s ease-out 0s;
                    transition: all .3s ease-out 0s;
                }

                &:hover:before {
                    opacity: 0.8;
                }

                &:hover {
                    transform: translateY(-5px);
                }
            }
        }
    }
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }

    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}


@media only screen and (max-width: 999px) {
    .hr-banner-img {
        height: 10rem;

    }

    .content-img-wrapper {
        padding: 0 1.5rem;
        flex-direction: column-reverse;

        img {
            width: 100%;
        }
    }

    .content-2-wrapper {
        flex-direction: column;
    }


    .container {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 2rem;

        .schedule-inner {
            // position: relative;
            // transform: translateY(-50%);
            // z-index: 9;

            .row {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                justify-content: space-between;

                .col-lg-4 {
                    -ms-flex: 0 0 100%;
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin-bottom: 1rem;

                    .single-schedule {
                        // position: relative;
                        text-align: left;
                        // z-index: 3;
                        border-radius: 5px;
                        background: #fff6f6;
                        -webkit-transition: all .3s ease-out 0s;
                        -moz-transition: all .3s ease-out 0s;
                        -ms-transition: all .3s ease-out 0s;
                        -o-transition: all .3s ease-out 0s;
                        transition: all .3s ease-out 0s;
                        // bottom: 0;

                        .inner {
                            overflow: hidden;
                            position: relative;
                            padding: 30px;
                            // z-index: 2;
                        }
                    }
                }
            }
        }
    }

}


@keyframes slideInFromLeftTitle {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.slideInFromLeftTitle-img {
        animation: slideInFromLeftTitle 2s ease-in-out;
}